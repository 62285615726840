export default {
  apiUrl: process.env.NODE_ENV === 'production' ? 'https://myfiitchallenge-425071658143.europe-west9.run.app' : 'http://localhost:5151', // apiUrl: process.env.NODE_ENV === 'production' ? window.location.origin + '/api' : 'http://localhost:5151',
}
const siteConfig = {
  siteName: 'MFC - Admin',
  siteIcon: 'ion-beer',
  footerText: 'MFC - Admin ©2019 Created with LOVE by BroStark',
}

export {
  siteConfig,
  
}
